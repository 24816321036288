import React, {useEffect} from "react";
import headliceWordmark from "../_static/images/headlice-wordmark-red.png";

const CampaignRedirect = () => {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const utmSource = urlParams.get("utm_source");
        const utmCampaign = urlParams.get("utm_campaign");

        if (utmSource == "tiktok" && utmCampaign == "ad_2") {
            window.location.href = "https://www.kickstarter.com/projects/bunguingames/headlice-a-reverse-horror-game?ref=bu6zyy";
        } else {
            window.location.href = "/";
        }

    }, []);

    return (
        <div className="game-page">
            <div className="page-header">
                <img src={headliceWordmark} width="60%" height="auto" alt="Cold Salvage logo"
                     className="game-wordmark"/>
            </div>
        </div>
    );
};

export default CampaignRedirect;
