import React, {Component, ReactElement, useEffect, useState} from "react";
import '../../_static/css/component/navigation/navigation.scss';
import {Link, useLocation} from "react-router-dom";
import DiscordNavLink from "./DiscordNavLink";
import logo from "../../_static/images/bg-hori-dark.png";
import sitemap from "../../App/sitemap.json";

class BrandLogo extends Component<{ visible: boolean }> {
    render() {
        let {visible} = this.props;
        if (!visible) {
            return <span className="navbar-item"><span className="logo"></span></span>;
        }
        return <a className="navbar-item" href="/">
            <img className="logo" src={logo} height="60"/>
        </a>;
    }
}

const linkMatch = (location: string, match: string): boolean => {
    if (match == "/") return location == match;
    return location.startsWith(match);
}

class NavLinkItem extends Component<{ path: any, title: any, sitemapKey: any, location: any }> {
    render() {
        let {path, title, sitemapKey, location} = this.props;
// @ts-ignore
        if (!sitemap[sitemapKey]) {
            return (<></>);
        }
        return (<Link className={"navbar-item " + (linkMatch(location.pathname, path) ? "selected" : "")} to={path}>
            {title}
        </Link>);
    }
}

const Navigation = () => {
    useEffect(() => {
        const $navbarBurgers: HTMLAnchorElement[] = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
        $navbarBurgers.forEach(el => {
            el.addEventListener('click', () => {
                const target = el.dataset.target;
                const $target = document.getElementById(target);
                el.classList.toggle('is-active');
                $target.classList.toggle('is-active');

            });
        });
    }, []);

    useEffect(() => {
        const $navbarBurgers: HTMLAnchorElement[] = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
        const $navbarItems: HTMLAnchorElement[] = Array.prototype.slice.call(document.querySelectorAll('.navbar-item'), 0);
        $navbarItems.forEach(item => {
            item.addEventListener('click', () => {
                $navbarBurgers.forEach(el => {
                    const target = el.dataset.target;
                    const $target = document.getElementById(target);
                    el.classList.remove('is-active');
                    $target.classList.remove('is-active');
                });
            });
        });
    }, []);

    const location = useLocation();
    const fixed = location.pathname == "/" ? "is-fixed-top" : "";
    return (<nav className={"navbar " + fixed} role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
            <BrandLogo visible={true}/>

            <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbar">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div id="navbar" className="navbar-menu">
            <div className="navbar-start navbar-center">
                <NavLinkItem path="/" title="Home" sitemapKey="home" location={location}/>
                <NavLinkItem path="/games" title="Our Games" sitemapKey="games" location={location}/>
                <NavLinkItem path="/team" title="Team" sitemapKey="team" location={location}/>
                <NavLinkItem path="/news" title="News" sitemapKey="news" location={location}/>
                <NavLinkItem path="/contact" title="Contact" sitemapKey="contact" location={location}/>
                <NavLinkItem path="/presskit" title="Press Kit" sitemapKey = "presskit" location={location} />
            </div>
            <div className="navbar-end">
                        <span className="navbar-item">
                        <DiscordNavLink/>
                        </span>
            </div>
        </div>
    </nav>);
};


export default Navigation;