import React from "react";
import "../../_static/css/component/promo/coldSalvage.scss";
import sitemap from "../../App/sitemap.json";
import coldSalvageWordmark from "../../_static/images/LogoWordmarkWide.png";
import coldSalvageScreenshot from "../../_static/images/coldsalvage-screenshot.png";
import {Link} from "react-router-dom";

const ColdSalvage = () => {
    if (!sitemap.home.coldSalvage) {
        return (<></>);
    }
    return <div className="coldsalvage">
        <div className="container">
            <div className="columns">
                <div className="column is-6">
                    <Link to="/games/cold-salvage"><img src={coldSalvageWordmark} alt="Cold Salvage logo"
                                                        className="coldsalvage-wordmark"/></Link>
                    <p>Cold Salvage is a co-op survival horror game set in dangerous abandoned wrecks.</p>
                    <br/>
                    <p>Load up on gear and enter unstable environments, avoiding hazards and creatures as you work to
                        claim your salvage.</p>
                    <br/>
                    <p>Explore. Repair. <em>Survive</em></p>
                    <a href="https://store.steampowered.com/app/2368320/Cold_Salvage/?utm_source=bunguingames&utm_campaign=web"
                       target="_blank" className="wishlist">Wishlist
                        on Steam</a>
                    <Link to="/games/cold-salvage" className="read-more">Read more</Link>
                </div>
                <div className="column is-6">
                    <Link to="/games/cold-salvage"><img src={coldSalvageScreenshot} width="100%" height="auto"
                                                        alt="Cold Salvage screenshot"
                                                        className="coldsalvage-screenshot"/></Link>
                </div>
            </div>
        </div>
    </div>;
}

export default ColdSalvage;