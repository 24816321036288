import React from "react";
import "../_static/css/view/games.scss";
import sitemap from "../App/sitemap.json";
import coldSalvageWordmark from "../_static/images/LogoWordmarkWide.png";
import coldSalvageScreenshot from "../_static/images/coldsalvage-screenshot.png";
import headliceWordmark from "../_static/images/headlice-wordmark-red.png";
import headliceScreenshot from "../_static/images/headlice-screenshot.png";
import {Link} from "react-router-dom";

const ColdSalvagePromo = () => {
    if (!sitemap.home.coldSalvage) {
        return <></>
    }
    return (<div className="column is-6">
        <div className="content">
            <Link to="/games/cold-salvage">
                <img src={coldSalvageWordmark} alt="Cold Salvage logo" className="coldsalvage-wordmark"/>
            </Link>
            <Link to="/games/cold-salvage">
                <img src={coldSalvageScreenshot} width="100%" height="auto" alt="Cold Salvage screenshot"
                     className="game-screenshot"/>
            </Link>
            <div className="game-description">
                <p>Cold Salvage is a co-op survival horror game set in dangerous abandoned wrecks.</p>
                <br/>
                <p>Load up on gear and enter unstable environments, avoiding hazards and creatures as you
                    work to claim your salvage.</p>
                <br/>
                <p>Explore. Repair. <em>Survive</em></p>
            </div>
            <div className="game-links">
                <a href="https://store.steampowered.com/app/2368320/Cold_Salvage/?utm_source=bunguingames&utm_campaign=web" target="_blank"
                   className="wishlist">Wishlist
                    on Steam</a>
                <Link to="/games/cold-salvage" className="read-more">Read more</Link>
            </div>
        </div>
    </div>);
};

const HeadlicePromo = () => {
    if (!sitemap.home.headlice) {
        return <></>
    }
    return (<div className="column is-6">
        <div className="content">
            <Link to="/games/headlice">
                <img src={headliceWordmark} alt="Headlice logo" className="headlice-wordmark"/>
            </Link>
            <Link to="/games/headlice">
                <img src={headliceScreenshot} width="100%" height="auto" alt="Headlice screenshot"
                     className="game-screenshot"/>
            </Link>
            <div className="game-description">
                <p>Headlice is a satirical body-horror experience where you play as a happy little parasite
                    looking
                    for a warm body.</p>
                <br/>
                <p>Jump, grapple, swing, and chomp your way to uncover the depravity of your creators as you
                    descend
                    further in to a corporate hellscape.</p>
            </div>
            <div className="game-links">
                <a href="https://store.steampowered.com/app/2851800/Headlice/?utm_source=bunguingames&utm_campaign=web" target="_blank"
                   className="wishlist">Wishlist
                    on Steam</a>
                <Link to="/games/headlice" className="read-more">Read more</Link>
            </div>
        </div>
    </div>);
};


function GamesView() {
    return (
        <div className="games-page">
            <div className="page-header">
                <div className="header-text has-text-centered">Our Games</div>
            </div>
            <div className="container pt-6">
                <div className="columns">
                    <HeadlicePromo/>
                    <ColdSalvagePromo/>
                </div>
            </div>
        </div>
    );
}

export default GamesView;
