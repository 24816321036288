import React from "react";
import "../_static/css/view/game.scss";
import coldSalvageWordmark from "../_static/images/LogoWordmarkWide.png";
import sitemap from "../App/sitemap.json";
import screenshot1 from "../_static/images/screenshots/coldsalvage/Screenshot-1.png";
import screenshot2 from "../_static/images/screenshots/coldsalvage/Screenshot-2.webp";
import screenshot3 from "../_static/images/screenshots/coldsalvage/Screenshot-3.webp";
import screenshot4 from "../_static/images/screenshots/coldsalvage/Screenshot-4.mp4";
import screenshot5 from "../_static/images/screenshots/coldsalvage/Screenshot-5.png";
import screenshot6 from "../_static/images/screenshots/coldsalvage/Screenshot-6.png";

const ColdSalvageView = () => {
    if (!sitemap.home.coldSalvage) {
        return <></>
    }
    return (<div className="game-page alt">
            <div className="page-header">
                <img src={coldSalvageWordmark} width="60%" height="auto" alt="Cold Salvage logo"
                     className="game-wordmark"/>
            </div>
            <div className="game-tagline">
                <p>Explore. Repair. <em>Survive.</em></p>
            </div>
            <div className="container pt-6">
                <div className="columns">
                    <div className="content">
                        <img className="header-screenshot" src={screenshot1} alt="Game screenshot"/>
                        <div className="mt-3 mb-6">
                            <a href="https://store.steampowered.com/app/2368320/Cold_Salvage/?utm_source=bunguingames&utm_campaign=web"
                               target="_blank" className="wishlist">Wishlist on Steam</a>
                        </div>
                        <p>Survival co-op mutiplayer, hand crafted by those who love the genre.</p>
                        <p>Take on salvage contracts, working in the cold depths of space - in a blue-collar job full of
                            danger and riches. Claim your own tug from the junkyards, and earn credits to upgrade and
                            repair it's systems. Then work with your team to return abandoned vessels to service... but
                            watch for what lurks in the dark.</p>
                    </div>
                </div>
            </div>
            <div className="game-tagline mt-6">
                Gallery
            </div>
            <div className="screenshots">
                <div className="container">
                    <div className="columns">
                        <div className="column is-4">
                            <img src={screenshot1} alt="Game screenshot"/>
                        </div>
                        <div className="column is-4">
                            <img src={screenshot2} alt="Game screenshot"/>
                        </div>
                        <div className="column is-4">
                            <img src={screenshot3} alt="Game screenshot"/>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-4">
                            <video preload="true" autoPlay loop muted poster={screenshot4}>
                                <source src={screenshot4} type="video/mp4"/>
                            </video>
                        </div>
                        <div className="column is-4">
                            <img src={screenshot5} alt="Game screenshot"/>
                        </div>
                        <div className="column is-4">
                            <img src={screenshot6} alt="Game screenshot"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="game-footer mt-6">
                These wrecks were abandoned for a reason
            </div>
        </div>
    );
};

export default ColdSalvageView;