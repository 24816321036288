import React from "react";
import "../_static/css/view/presskit.scss";
import Claws from "../_static/press/headlice/Claws.png";
import CrabPoseIdle from "../_static/press/headlice/CrabPoseIdle.png";
import CrabPoseJump from "../_static/press/headlice/CrabPoseJump.png";
import LogoMain from "../_static/press/headlice/LogoMain.png";
import WordmarkDark from "../_static/press/headlice/WordmarkDark.png";
import WordmarkLight from "../_static/press/headlice/WordmarkLight.png";
import WordmarkRed from "../_static/press/headlice/WordmarkRed.png";
import ColdSalvageLogo from "../_static/press/coldsalvage/Logo.png";
import ColdSalvageLogoBasic from "../_static/press/coldsalvage/LogoBasic.png";
import ColdSalvageLogoInv from "../_static/press/coldsalvage/LogoInv.png";
import ColdSalvageLogoWordmark from "../_static/press/coldsalvage/LogoWordmark.png";
import ColdSalvageLogoWordmarkInv from "../_static/press/coldsalvage/LogoWordmarkInv.png";
import ColdSalvageWordmark from "../_static/press/coldsalvage/Wordmark.png";
import ColdSalvageWordmarkInv from "../_static/press/coldsalvage/WordmarkInv.png";

import LogoWhite from "../_static/press/studio/LogoWhite.png";
import LogoHorizontal from "../_static/press/studio/LogoHorizontal.png";

const BunguinGamesAssets = '/BunguinGamesAssets.zip';
const ColdSalvageAssets = '/ColdSalvageAssets.zip';
const HeadliceAssets = '/HeadliceAssets.zip';

function PresskitView() {
    return (
        <div className="presskit-page">
            <div className="page-header">
                <div className="header-text has-text-centered">Press Kit</div>
            </div>
            <div className="presskit-content">
                <div className="container">
                    <div className="columns">
                        <div className="column is-6">
                            <div className="presskit-header"><em>Headlice</em> – Press Kit</div>
                            <p className="subtitle">Physics-Based Reverse Horror</p>
                            <p>In a darkly humorous, physics-based action horror game, a horrifyingly cute parasite must
                                swing and chomp its way through the twisted halls of The Coffee Corp to escape the
                                deadly clutches of its creator, eliminating threats, fueling its hybrid biology with
                                human prey, and manipulating meat-puppets along the way. In <em>Headlice</em> you may be
                                the villain, but your creator is the true monster.</p>
                            <p><em>Headlice</em> is a strange brew - a rich blend of horror and action, with a pinch of
                                absurd.</p>
                            <ul>
                                <li>Explore varied environments filled with dangers and biological abominations</li>
                                <li>Possess humans and use them as snacks, or as meat-puppets to unlock new areas</li>
                                <li>Cause mayhem with destruction physics, vehicles and parasitic abilities</li>
                                <li>Free other experimental animals from the clutches of The Coffee Corps</li>
                                <li>Grow into the monster you deserve to be with powerups and abilities</li>
                            </ul>
                            <p><em>Headlice</em> is presented using the Unity engine, and will be available on MacOS,
                                Windows and Linux, with an initial launch on Steam</p>
                            <ul>
                                <li>Genre: Reverse Horror, Physics-Based, Action-Stealth</li>
                                <li>Platforms: PC &amp; MacOS (Steam)</li>
                                <li>Release Date: Q3 2025</li>
                                <li><a
                                    href="https://store.steampowered.com/app/2851800/Headlice/?utm_source=bunguingames&utm_campaign=web"
                                    target="_blank">Steam page</a></li>
                                <li>Price: TBD</li>
                            </ul>
                            <p><a href={HeadliceAssets} target="_blank">Download Headlice assets</a></p>
                            <div>
                                <a href={Claws} target="_blank"><img className="brand-image" src={Claws}/></a>
                                <a href={CrabPoseIdle} target="_blank"><img className="brand-image" src={CrabPoseIdle}/></a>
                                <a href={CrabPoseJump} target="_blank"><img className="brand-image" src={CrabPoseJump}/></a>
                                <a href={LogoMain} target="_blank"><img className="brand-image" src={LogoMain}/></a>
                                <a href={WordmarkDark} target="_blank"><img className="brand-image inv" src={WordmarkDark}/></a>
                                <a href={WordmarkLight} target="_blank"><img className="brand-image"
                                                                             src={WordmarkLight}/></a>
                                <a href={WordmarkRed} target="_blank"><img className="brand-image"
                                                                           src={WordmarkRed}/></a>
                            </div>
                        </div>
                        <div className="column is-6">
                            <div className="presskit-header"><em>Cold Salvage</em> – Press Kit</div>
                            <p className="subtitle">Survival Co-op Horror</p>
                            <p>Take on salvage contracts, working in the cold depths of space - in a blue-collar job
                                full of danger and riches. Claim your own tug from the junkyards, and earn credits to
                                upgrade and repair it's systems. Then work with your team to return abandoned vessels to
                                service... but watch for what lurks in the dark.</p>
                            <ul>
                                <li>Claim your salvage tug from the scrapyards</li>
                                <li>Enter abandoned warships and return them to the docks for repairs</li>
                                <li>Avoid the biological weapons left to roam the drifting hulks</li>
                                <li>Earn and retrieve raw materials to feed in to your machine shop</li>
                                <li>Craft and upgrade your tug to take on more lucrative - and dangerous - contracts
                                </li>
                            </ul>
                            <p><em>Cold Salvage</em> is presented using the Unity engine, and will be available on
                                MacOS,
                                Windows and Linux, with an initial launch on Steam - followed by latest-gen consoles</p>
                            <ul>
                                <li>Genre: Survival Horror, Co-operative, Stealth, Crafting</li>
                                <li>Platforms: PC &amp; MacOS (Steam), Xbox, Playstation with crossplay</li>
                                <li>Release Date: 2026</li>
                                <li><a
                                    href="https://store.steampowered.com/app/2368320/Cold_Salvage/?utm_source=bunguingames&utm_campaign=web"
                                    target="_blank">Steam page</a></li>
                                <li>Price: TBD</li>
                            </ul>
                            <p><a href={ColdSalvageAssets} target="_blank">Download Cold Salvage assets</a></p>
                            <div>
                                <a href={ColdSalvageLogo} target="_blank"><img className="brand-image"
                                                                               src={ColdSalvageLogo}/></a>
                                <a href={ColdSalvageLogoBasic} target="_blank"><img className="brand-image"
                                                                                    src={ColdSalvageLogoBasic}/></a>
                                <a href={ColdSalvageLogoInv} target="_blank"><img className="brand-image"
                                                                                  src={ColdSalvageLogoInv}/></a>
                                <a href={ColdSalvageLogoWordmark} target="_blank"><img className="brand-image"
                                                                                      src={ColdSalvageLogoWordmark}/></a>
                                <a href={ColdSalvageLogoWordmarkInv} target="_blank"><img className="brand-image inv"
                                                                                      src={ColdSalvageLogoWordmarkInv}/></a>
                                <a href={ColdSalvageWordmark} target="_blank"><img className="brand-image"
                                                                                  src={ColdSalvageWordmark}/></a>
                                <a href={ColdSalvageWordmarkInv} target="_blank"><img className="brand-image inv"
                                                                                      src={ColdSalvageWordmarkInv}/></a>
                            </div>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-12">
                            <div className="presskit-header">Bunguin Games Studio – Press Kit</div>
                            <p className="subtitle">Independent studio based in New Zealand</p>
                            <p>Formed in 2022, Bunguin Games is a supporter of the New Zealand games industry. We hire
                                local, and export global.</p>
                            <p>Our studio focus is on horror games.</p>
                            <p><a href={BunguinGamesAssets} target="_blank">Download studio assets</a></p>
                            <div>
                                <a href={LogoWhite} target="_blank"><img className="brand-image"
                                                                         src={LogoWhite}/></a>
                                <a href={LogoHorizontal} target="_blank"><img className="brand-image"
                                                                              src={LogoHorizontal}/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

export default PresskitView;
