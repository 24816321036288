import React from "react";
import imageGareth from "../_static/images/team/ProfilePic_GarethFox.png";
import imageNone from "../_static/images/team/ProfilePic_None.png";
import imageDorren from "../_static/images/team/ProfilePic_DorrenAndrews.png";

import "../_static/css/view/team.scss";

function TeamView() {
    return (
        <div className="team-page">
            <div className="page-header">
                <div className="header-text has-text-centered">Team</div>
            </div>
            <div className="teeth">
                <div className="teeth-top"></div>
                <p>Bunguin Games is a supporter of the New Zealand games industry.</p>
                <p>We hire <span className="emphasis">local</span>, and export <span className="emphasis">global</span></p>
                <div className="teeth-bottom"></div>
            </div>
            <div className="emeritus">
                Studio Team
            </div>
            <div className="container pt-6">
                <div className="columns">
                    <div className="column is-4 team-card">
                        <img className="team-photo" alt="Gareth Fox" src={imageGareth}/>
                        <div className="team-name">Gareth <span className="mixed-case">"TheAyshe"</span> Fox</div>
                        <div className="team-title">Managing Director</div>
                        <div className="team-text">
                            <p>Founder of Bunguin Games, has been lucky to find some really great people to work with. Makes soft toys as a hobby. #TeamTifa</p>
                        </div>
                        <a href="https://www.linkedin.com/in/garethfoxnz/" className="team-link" target="_blank">linkedin.com/garethfoxnz</a>
                    </div>
                    <div className="column is-4 team-card">
                        <img className="team-photo" alt="Dorren Andrews" src={imageDorren}/>
                        <div className="team-name">Dorren <span className="mixed-case">"Dozz8u"</span> Andrews</div>
                        <div className="team-title">3D Generalist</div>
                        <div className="team-text">
                            <p>As a joke we started assigning him unity programming tasks. We've yet to find one he can't do. Suspiciously versatile.</p>
                        </div>
                        <a href="https://www.dorrenandrews.com/" className="team-link" target="_blank">dorrenandrews.com</a>
                    </div>
                    <div className="column is-4 team-card">
                        <img className="team-photo" alt="No photo" src={imageNone}/>
                        <div className="team-name">Devon Millar</div>
                        <div className="team-title">Game Programmer</div>
                        <div className="team-text">
                            <p>Might be a vampire, no known photos exist. Solid programmer though.</p>
                        </div>
                        <a href="https://www.devonmillar.com/" className="team-link" target="_blank">devonmillar.com</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeamView;
